@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.square {
  width: 50%;
  padding-bottom: 50%;
  background-color: teal;
}
div.MuiDataGrid-selectedRowCount{
  font-weight: bold !important;
  color: green !important;
  font-size: medium;
}
.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}

.accomplished-span{
  font-size: 1.25em;
  color: #555555;
  position: absolute;
  top: -78%;
  width: 200px;
  text-align: center;
}

.property-cols{
  border-right: 1px solid black;
}

.form-label{
  margin-bottom: 0rem !important;
  margin-top: 0.5rem !important;
}

.prePopulated, .prePopulated>div{
  background-color: #E5FDD1 !important;
}
.danger-input, .danger-input>div{
  background-color: #FFA07A !important;
}

.Collapsible__contentInner{
  height: 60vh !important;
}