#root{
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar{
  background-color: #e3f2fd !important;
  width: 100%;
}


.footer{
  background-color:white;
  color: blue;
  text-align: center;
  font-weight: bold;
  width: 89%;
  height: 6%;
  bottom: 0px;
  margin:0;
  position: fixed;
  border-top:1px solid red;
}

.brand-logo{
  height: 50px;
}

.loadingBtn{
  width: 100%;
}

.client-logo{
  width: 69px;
}

.sign-out-link{
  width: 80px;
}

.header-bold{
  background-color: #e3f2fd !important;
}

.MuiDataGrid-columnHeaderTitle{
  font-weight: bold !important;
}

.danger-row{
  background-color: #FF5733;
}

.warning-row{
  background-color: #FFBF00;
}

div[class$="MuiDataGrid-selectedRowCount"]{
  font-weight: bold !important;
  color: green !important;
  font-size: medium;
}

.pac-container {
  background-color: #151515;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.pac-item,
.pac-item-query {
  color: #f7f7f7;
}
.pac-item:hover {
  background: #6666;
}

.editBondStyle{
  display: inline-grid;
  padding: 0% 2% 0% 0%;
}
.editBondStyleHeight{
  display: inline-grid;
  padding: 0% 2% 1% 0%;
}
.editBondStyleMinHeight{
  display: inline-grid;
  padding: 0% 2% 0.5% 0%;
}
.editBondStylePDF{
   display: inline-grid;
   padding: 0% 2% 3.5% 0%;
 }

.approveRows {
  background-color: #dbffd0;
}
.approveRows:hover{
  background-color: #cbefc0;
}