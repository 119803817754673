.root {
    position: fixed;
    display: flex;
    justify-content: space-between;
    
    padding-top: 20px;
    

    width: 100%;
    z-index: 2;
    background-color: rgb(248 250 252);

}

.nav {
    margin-left: 40px;
}