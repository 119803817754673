.no-spin {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
  }
  
  .no-spin::-webkit-inner-spin-button,
  .no-spin::-webkit-outer-spin-button {
    display: none;
  }
  
  .no-spin::-moz-number-spin-box {
    display: none;
  }

.capital-project-heading {
    padding: 5px;
  }