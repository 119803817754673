.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-error {
    color: red;
    padding: 3px;
    font-size: small;
}

.line-item-border {
    border: 2px solid grey;
    border-radius: 5%;
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
}

.line-item-text {
    margin-top: -3.5%;
    margin-left: 4%;
    background: white;
    width: 25%;
    text-align: center;
    font-weight: bold;
}

.line-item-btn {
    margin-top: -19.5%;
    margin-left: 72%;
    background: white;
    width: 25%;
    text-align: center;
    font-weight: bold;
}

.small-select-field {
    display: inline-block !important;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #6CB4EE !important;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {

    background: #555;
}

.spaceCard {
    padding: 1% 1% 1% 1%;
    text-align: center;

}

.required-label:after {
    content: "*";
    color: red;
}

.navbar {
    padding: 0% 5% 0% !important;
}

@media screen and (min-width: 2500px) {
    .navbar {
        padding: 0% 3% 0% !important;
    }
}

.register-div {
    padding-bottom: 1% !important;
    overflow: visible !important;
    max-width: 50% !important;
}

@media screen and (max-width: 1500px) {
    .register-div {
        padding-bottom: 1% !important;
        overflow: visible !important;
        max-width: 60% !important;
    }
}

@media screen and (max-width: 1200px) {
    .register-div {
        padding-bottom: 1% !important;
        overflow: visible !important;
        max-width: 65% !important;
    }
}

@media screen and (max-width: 1100px) {
    .register-div {
        padding-bottom: 1% !important;
        overflow: visible !important;
        max-width: 75% !important;
    }
}

.comment-span * {
    display: inline;
}

.card:hover {
    background-color: #243bec !important;
    color: #e3f2fd !important;
}

.card-body ul {
    padding-left: 1%
}

.card {
    background-color: #e3f2fd !important;
    color: #243bec !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.custom-field {
    position: relative;
    font-size: 14px;
    border-top: 20px solid transparent;
    display: inline-block;
    --field-padding: 12px;
    color: #243bec !important;
}

.custom-field input {
    border: 1px solid #243bec;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: var(--field-padding);
    border-radius: 3px;
    width: 190px;
    outline: none;
    font-size: 14px;
}

.input1 {
    border: 1px solid #243bec;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    width: 190px;
    outline: none;
    font-size: 14px;
}

.bond-creater {
    padding-top: 10px;
}

/*.fixed-height {*/
/*    height: 100px;*/
/*}*/

.form-control-bond-calculator {
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.form-control-bond-calculator-disabled {
    background-color: #e9ecef;
    opacity: 1;
}